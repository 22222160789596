import React from "react";
import Layout from "../../components/organisms/layout/layout";
import SEO from "../../shared/seo";
import BodyFormAutorizacionDatosYCondiciones from "../../components/organisms/body-form-autorizacion-datos-y-condiciones/bodyFormAutorizacionDatosYCondiciones";
import { useStaticQuery, graphql } from "gatsby";

const AutorizacionDatosYCondicionesPage = () => {
    const data = useStaticQuery(graphql`
        query contentfulFormAutorizacionDesarrolloEmpresarial {
            allContentfulContactenos(
                filter: {
                    titulo: {
                        eq: "Información Contáctanos Autorización Datos Desarrollo empresarial"
                    }
                }
            ) {
                nodes {
                    tituloPrincipal
                    descripcionFormulario {
                        json
                    }
                    titulosFormulario {
                        tituloGeneral
                        referenciaFormulario {
                            tipoCampo
                            listaSelect
                            mensajeError
                            inputPrincipal
                            nombreId
                            tituloBotonTerminos
                            textoTerminos {
                                json
                            }
                            obligatorio
                        }
                    }
                    imagenesEmpleo {
                        file {
                            url
                        }
                        title
                        fluid(maxWidth: 960, quality: 75) {
                            src
                            srcSet
                            base64
                            aspectRatio
                            sizes
                        }
                    }
                }
            }
        }
    `);

    return (
        <Layout
            hideHeaderDesktop={true}
            hideHeaderMobile={true}
            isNotRoot={true}
        >
            <SEO
                title="Desarrollo Empresarial - Autorización manejo de datos y condiciones"
                lang="es"
            />
            <BodyFormAutorizacionDatosYCondiciones
                {...data.allContentfulContactenos.nodes[0]}
            />
        </Layout>
    );
};
export default AutorizacionDatosYCondicionesPage;
