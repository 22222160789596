import React, { useEffect, useRef } from "react"
import "./highlightTextarea.scss";

const HighlightTextarea = (props: HighlightTextareaInterface) => {
	const {
		nameId,
		value,
		maxLength,
		handleChange
	} = props
	
	const textAreaRef = useRef(null);

	const onTweetCompose = () => {
		var placeholderBacker = document.querySelector('.keeper-placeholder-back');
		if(textAreaRef.current) {
			var currentValue = textAreaRef.current.value;
			var realLength = maxLength || 200;
			var remainingLength = (maxLength || 200) - currentValue.length;
			if (0 > remainingLength) {
				var allowedValuePart = currentValue.slice(0, realLength);
				var refusedValuePart = currentValue.slice(realLength);
				if(placeholderBacker) {
					placeholderBacker.innerHTML = allowedValuePart + '<em>' + refusedValuePart + '</em>';
				}
			} else {
				if(placeholderBacker)
					placeholderBacker.innerHTML = '';
			}
		}
		
	}

	useEffect(() => {
		var placeholderBacker = document.createElement("div");
		placeholderBacker.classList.add('keeper-placeholder-back');
		onTweetCompose();
		['selectionchange', 'copy', 'paste', 'cut', 'mouseup', 'input'].forEach(function(e) {
			window.addEventListener(e, onTweetCompose);
		});
	}, [])

	return (
		<div className="card">
			<div className="text-composer">
				<textarea ref={textAreaRef}
					className="keeper-editor"
					id={nameId}
					onChange={
							(event) => handleChange(event.target.value, nameId)
					}>{value}</textarea>
				<div className="keeper-placeholder-back"></div>
			</div>
		</div>
	)
}

interface HighlightTextareaInterface {
	nameId: string;
	value: string;
	maxLength?: number;
	handleChange: (value: any, name?: string) => void;
}

export default HighlightTextarea;
