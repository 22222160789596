import React, { useEffect, useState, useRef, MutableRefObject } from "react";
import "./inputForm.scss";
import { INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Tooltip from "../tooltip/tooltip";
import HighlightTextarea from "../highlight-textarea/highlightTextarea";

interface IInputForm {
    title: string;
    value: string;
    nameId: string;
    type:
        | "select"
        | "text"
        | "text-area"
        | "number"
        | "predict"
        | "date"
        | "radio"
        | "checkbox";
    options?: Array<any>;
    maxLength?: number;
    isError?: boolean;
    errorMessage?: string;
    gigyaAccount?: any;
    placeholder?: any;
    selectOtherTitle?: any;
    handleChange: (value: any, name?: string) => void;
}

const InputForm = (props: IInputForm) => {
    const contentfulRenderOptions = {
        renderNode: {
            [INLINES.HYPERLINK]: ({ data }, children) => (
                <a
                    style={{ color: "#EE2B7B", textDecoration: "underline" }}
                    href={data.uri}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                >
                    {children}
                </a>
            ),
        },
        renderText: (text) =>
            text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
    };

    const node: MutableRefObject<any> = useRef();

    const {
        title,
        value,
        nameId,
        type,
        options,
        maxLength,
        isError,
        errorMessage,
        gigyaAccount,
        placeholder,
        selectOtherTitle,
        handleChange,
    } = props;

    const [isActive, setActiveSelect] = useState(false);
    const [isRadioOtherChecked, setIsRadioOtherChecked] = useState(false);
    const [radioOtherValue, setRadioOtherValue] = useState("");
    const [isSelectOtherSelected, setIsSelectOtherSelected] = useState(false);
    const [selectValue, setSelectValue] = useState(value);
    const [selectOtherValue, setSelectOtherValue] = useState("");

    // let ref = useRef(null);

    const handleClickOutside = (e) => {
        if (node.current.contains(e.target)) {
            return;
        } else {
            setActiveSelect(false);
        }
    };

    const validateNumber = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === "" || re.test(event.target.value)) {
            handleChange(event.target.value, nameId);
        }
    };

    useEffect(() => {
        if (isActive) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isActive]);

    useEffect(() => {
        if (type == "select" && value != "Otro") {
            if (
                (options || [])
                    .map((option) => {
                        return option.split(":")[1] || option;
                    })
                    .includes(value)
            ) {
                setSelectValue(value);
            } else {
                if (value != "") {
                    setSelectOtherValue(value);
                    setSelectValue("Otro");
                    setIsSelectOtherSelected(true);
                }
            }
        }

        if (type == "radio" && value != "Otro") {
            if (
                (options || [])
                    .map((option) => {
                        return option.split("#")[0].split(":")[0] || option;
                    })
                    .includes(value)
            ) {
                setRadioOtherValue("");
                setIsRadioOtherChecked(false);
            } else {
                if (value != "" && value == "Otro") {
                    setIsRadioOtherChecked(true);
                    setRadioOtherValue(value);
                }
                if (value != "" && value != "Otro") {
                    setRadioOtherValue(value);
                    setIsRadioOtherChecked(true);
                }
            }
        }
    }, [value]);

    const inputTypeComponent = () => {
        switch (type) {
            case "select":
                return (
                    <>
                        <label
                            htmlFor={"select_" + nameId}
                            className={"a-input__form-demp__hidden hide_text"}
                        >
                            {title}
                        </label>
                        <select
                            className="a-input__form-demp a-input__form-demp-select"
                            id={nameId}
                            onClick={() => {
                                gigyaAccount &&
                                ["gender", "idNumType"].includes(nameId)
                                    ? setActiveSelect(false)
                                    : setActiveSelect(!isActive);
                            }}
                            ref={node}
                            value={selectValue ?? ""}
                            onChange={(e) => {
                                setSelectValue(e.target.value);
                                handleChange(e.target.value, nameId);
                                if (e.target.value == "Otro") {
                                    setSelectOtherValue("");
                                    setIsSelectOtherSelected(true);
                                    handleChange("", nameId);
                                } else {
                                    setIsSelectOtherSelected(false);
                                }
                            }}
                            disabled={ gigyaAccount && ["docType"].includes(nameId) ? true : false }
                        >
                            <>
                                <option
                                    className="a-input__form-demp a-input__form-demp-select"
                                    key={0}
                                    value=""
                                >
                                    Selecciona
                                </option>
                                {(options || []).map((option, index) => {
                                    return (
                                        <option
                                            className="a-input__form-demp a-input__form-demp-select"
                                            key={index}
                                            value={
                                                option.split(":")[1] || option
                                            }
                                        >
                                            {option.split(":")[0] || option}
                                        </option>
                                    );
                                })}
                            </>
                        </select>
                        {selectValue == "Otro" ? (
                            <div className={"a-input__form-demp-select__other"}>
                                <small
                                    className={
                                        "a-input__form-demp-select__other-small"
                                    }
                                >
                                    {selectOtherTitle || "Ingrese otro valor"}
                                </small>
                                <input
                                    style={{ marginTop: "10px" }}
                                    className={`a-input__form-demp__field${
                                        isError
                                            ? " a-input__form-demp__field--error"
                                            : ""
                                    }`}
                                    type="text"
                                    id={nameId + "_otro"}
                                    name={nameId}
                                    value={selectOtherValue}
                                    disabled={!isSelectOtherSelected}
                                    onChange={(event) => {
                                        setSelectOtherValue(event.target.value);
                                        handleChange(
                                            event.target.value,
                                            nameId
                                        );
                                    }}
                                    placeholder={"Otro"}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                );
            case "text":
                return (
                    <>
                        <label
                            htmlFor={nameId}
                            className={"a-input__form-demp__hidden hide_text"}
                        >
                            {title}
                        </label>
                        <input
                            className={`a-input__form-demp__field${
                                isError
                                    ? " a-input__form-demp__field--error"
                                    : ""
                            }`}
                            id={nameId}
                            name={title}
                            type={nameId === "email" ? "email" : type}
                            value={value ?? ""}
                            maxLength={maxLength || 100}
                            disabled={["email", "docNum", "fullName"].includes(
                                nameId
                            )}
                            onChange={(event) =>
                                handleChange(event.target.value, nameId)
                            }
                            placeholder={placeholder ? placeholder : ""}
                        />
                    </>
                );
            case "number":
                return (
                    <>
                        <label
                            htmlFor={nameId}
                            className={"a-input__form-demp__hidden hide_text"}
                        >
                            {title}
                        </label>
                        <input
                            className={`a-input__form-demp__field${
                                isError
                                    ? " a-input__form-demp__field--error"
                                    : ""
                            }`}
                            id={nameId}
                            name={title}
                            type={"text"}
                            maxLength={maxLength || 11}
                            value={value ?? ""}
                            disabled={false}
                            onChange={(event) => validateNumber(event)}
                            placeholder={placeholder ? placeholder : ""}
                        />
                    </>
                );
            case "checkbox":
                return (
                    <div style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            id={nameId}
                            value={value ?? ""}
                            onChange={(event) =>
                                handleChange(event.target.checked, nameId)
                            }
                        />
                        <span style={{ paddingLeft: "10px" }}>
                            {documentToReactComponents(
                                placeholder,
                                contentfulRenderOptions
                            )}
                        </span>
                    </div>
                );
            case "text-area":
                return (
                    <HighlightTextarea
                        nameId={nameId}
                        value={value}
                        handleChange={handleChange}
                    ></HighlightTextarea>
                );
            case "date":
                return (
                    <>
                        <label
                            htmlFor={nameId}
                            className={"a-input__form-demp__hidden hide_text"}
                        >
                            {title}
                        </label>
                        <input
                            type="date"
                            id={nameId}
                            name={title}
                            value={value ?? ""}
                            onChange={(e) =>
                                handleChange(e.target.value, nameId)
                            }
                            onKeyDown={(e) => e.preventDefault()}
                            disabled={ gigyaAccount && ["birthDate"].includes(nameId) ? true : false }
                        />
                    </>
                );
            case "radio":
                return (
                    <div className="radio-container">
                        {(options || []).map((option, _index) => {
                            return (
                                <div
                                    className="bordered-radio"
                                    id={nameId}
                                    onClick={(_e) =>
                                        handleChange(
                                            option
                                                .split("#")[0]
                                                .split(":")[1] ||
                                                option.split("#")[0],
                                            nameId
                                        )
                                    }
                                >
                                    <input
                                        type="radio"
                                        id={nameId + "_" + option}
                                        name={nameId}
                                        value={
                                            (option
                                                .split("#")[0]
                                                .split(":")[1] ||
                                                option.split("#")[0]) ??
                                            ""
                                        }
                                        checked={
                                            (option
                                                .split("#")[0]
                                                .split(":")[0] || option) !=
                                            "Otro"
                                                ? (option
                                                      .split("#")[0]
                                                      .split(":")[0] ||
                                                      option) == props.value
                                                : isRadioOtherChecked
                                        }
                                        defaultChecked={
                                            (option
                                                .split("#")[0]
                                                .split(":")[0] || option) !=
                                            "Otro"
                                                ? (option
                                                      .split("#")[0]
                                                      .split(":")[0] ||
                                                      option) == props.value
                                                : isRadioOtherChecked
                                        }
                                        onChange={(e) => {
                                            if (
                                                (option
                                                    .split("#")[0]
                                                    .split(":")[0] || option) ==
                                                "Otro"
                                            ) {
                                                handleChange(
                                                    radioOtherValue,
                                                    nameId
                                                );
                                                setIsRadioOtherChecked(true);
                                            } else {
                                                handleChange(
                                                    e.target.value,
                                                    nameId
                                                );
                                                setIsRadioOtherChecked(false);
                                            }
                                        }}
                                    />
                                    {(option.split("#")[0].split(":")[0] ||
                                        option) != "Otro" ? (
                                        <small>
                                            {option
                                                .split("#")[0]
                                                .split(":")[0] || option}
                                        </small>
                                    ) : (
                                        <input
                                            className="bordered-radio__other-input a-input__form-demp__field"
                                            type="text"
                                            id={nameId + "_" + option}
                                            name={nameId}
                                            value={radioOtherValue}
                                            disabled={!isRadioOtherChecked}
                                            onChange={(event) => {
                                                setRadioOtherValue(
                                                    event.target.value
                                                );
                                                handleChange(
                                                    event.target.value,
                                                    nameId
                                                );
                                            }}
                                            placeholder={"Otro"}
                                        />
                                    )}
                                    {option.split("#")[1] && (
                                        <div className="bordered-radio__tooltip-container">
                                            <Tooltip
                                                title={
                                                    option
                                                        .split("#")[0]
                                                        .split(":")[0] || option
                                                }
                                                text={option.split("#")[1]}
                                            ></Tooltip>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                );
        }
    };

    return (
        <div className={`a-input__form-demp a-input__form-demp-${type}`}>
            {inputTypeComponent()}
            {isError && (
                <div className="a-input__form-demp__title__error">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default InputForm;
