import React from "react";
import "./bodyFormAutorizacionDatosYCondiciones.scss";
import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";
import FormAutorizacionDatosYCondiciones from "../form-autorizacion-datos-y-condiciones/formAutorizacionDatosYCondiciones";

interface IBodyForm {
    tituloPrincipal: string;
    descripcionFormulario: any;
    titulosFormulario: Array<any>;
    imagenesEmpleo: any;
}

const BodyFormAutorizacionDatosYCondiciones = (props: IBodyForm) => {
    const contentfulRenderOptions = {
        renderNode: {
            [INLINES.HYPERLINK]: ({ data }, children) => (
                <a
                    style={{ color: "#EE2B7B", textDecoration: "underline" }}
                    href={data.uri}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                >
                    {children}
                </a>
            ),
        },
        renderText: (text) =>
            text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
    };

    return (
        <>
            <div className="o-bodyForm-autorizacion-datos-condiciones">
                <div className="o-bodyForm-autorizacion-datos-condiciones__left">
                    <Breadcrumb content={`Inicio / Ley de Emprendimiento`} />
                    <div
                        className={`o-bodyForm-autorizacion-datos-condiciones__header`}
                    >
                        <h1 className="o-bodyForm-autorizacion-datos-condiciones__header-title">
                            {props.tituloPrincipal}
                        </h1>
                        <picture>
                            <source
                                srcSet={props.imagenesEmpleo[2].file.url}
                                media="(prefers-color-scheme: dark)"
                            />
                            <source
                                srcSet={props.imagenesEmpleo[3].file.url}
                                media="(prefers-color-scheme: light)"
                            />
                            <img
                                src={props.imagenesEmpleo[3].file.url}
                                className="side-image-autorizacion__mobile"
                            />
                        </picture>

                        <p className="o-bodyForm-autorizacion-datos-condiciones__header-subtitle">
                            {documentToReactComponents(
                                props.descripcionFormulario.json,
                                contentfulRenderOptions
                            )}
                        </p>
                        <FormAutorizacionDatosYCondiciones
                            {...props.titulosFormulario}
                        />
                    </div>
                </div>
                <div className="o-bodyForm-autorizacion-datos-condiciones__right">
                    <picture>
                        <source
                            srcSet={props.imagenesEmpleo[0].file.url}
                            media="(prefers-color-scheme: dark)"
                        />
                        <source
                            srcSet={props.imagenesEmpleo[1].file.url}
                            media="(prefers-color-scheme: light)"
                        />
                        <img
                            src={props.imagenesEmpleo[1].file.url}
                            className="side-image-autorizacion"
                        />
                    </picture>
                </div>
            </div>
        </>
    );
};

export default BodyFormAutorizacionDatosYCondiciones;
