import React, { useState, useContext } from "react";
import "./formAutorizacionDatosYCondiciones.scss";
import Button from "../../atoms/button/button";
import Loading from "../../atoms/loading/loading";
import GlobalContext from "../../../contexts/globalContext";
import InputForm from "../../atoms/input-form-demp/inputForm";

const FormAutorizacionDatosYCondiciones = (props: any) => {
    const formFields = props;

    const context = useContext(GlobalContext);

    const [showErrors, setShowErrors] = useState(false);

    let defaultFormValues = {};
    let mandatoryFields: string[] = [];
    formFields[0].referenciaFormulario.map((field) => {
        defaultFormValues[field.nombreId] = "";
        if (field.obligatorio) {
            mandatoryFields.push(field.nombreId);
        }
    });

    const [formValues, setFormValues] = useState(defaultFormValues);

    const updateFormValue = (field: string, value: any) => {
        setFormValues({ ...formValues, [field]: value });
    };

    const isDisabledSubmitButton = () => {
        return !Object.entries(formValues).every(
            ([key, value]) =>
                !mandatoryFields.includes(key) ||
                (value !== "" && value == true)
        );
    };

    const canContinue = () => {
        if (!isDisabledSubmitButton()) {
            submitForm();
            setShowErrors(false);
        } else {
            for (const field of mandatoryFields) {
                if (formValues[field] == "" || formValues[field] == false) {
                    document.getElementById(field)?.scrollIntoView({
                        behavior: "auto",
                        block: "center",
                        inline: "center",
                    });
                    break;
                }
            }
            setShowErrors(true);
        }
    };

    const errorDetected = (input): boolean => {
        if (
            mandatoryFields.includes(input.nombreId) &&
            formValues[input.nombreId] == "" &&
            showErrors
        ) {
            return true;
        } else {
            return false;
        }
    };

    const createInput = (input, index) => {
        return (
            <div
                className="input__container__first"
                key={index}
                id={`container_${input.nombreId}`}
            >
                <h6 className="input__container__h6">{input.inputPrincipal}</h6>
                {input.tituloBotonTerminos && (
                    <>
                        <small className="input__container__small">
                            {input.tituloBotonTerminos}
                        </small>
                        <br />
                        <br />
                    </>
                )}
                <InputForm
                    key={`inp${index}`}
                    title={input.inputPrincipal}
                    type={input.tipoCampo}
                    nameId={input.nombreId}
                    value={formValues[input.nombreId]}
                    handleChange={(e) => updateFormValue(input.nombreId, e)}
                    isError={errorDetected(input)}
                    errorMessage={input.mensajeError}
                    options={input.listaSelect || null}
                    placeholder={input.textoTerminos.json}
                />
            </div>
        );
    };

    const submitForm = () => {
        localStorage.setItem("isDataProcessingAuthorized", "true");
        localStorage.setItem("isParticipationConditionsAuthorized", "true");
        window.location.href =
            "/desarrollo-empresarial/inscripcion-desarrollo-empresarial";
    };

    return (
        <div>
            {!context.gigyaContext.gigyaAccount ||
            localStorage.getItem("isAffiliatedCompany") !== "true" ||
            localStorage.getItem("isLegalRepresentative") !== "true" ? (
                <Loading />
            ) : (
                <div>
                    <div className="o-form-autorizacion-datos-condiciones">
                        <div
                            id="groupIntermediacion0"
                            className="o-form-autorizacion-datos-condiciones__group--open"
                        >
                            <div className="o-form-autorizacion-datos-condiciones__group-cont">
                                {formFields &&
                                    formFields[0].referenciaFormulario.map(
                                        (field, i) => createInput(field, i)
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="o-form-autorizacion-datos-condiciones__bottom">
                        <div className="o-form-autorizacion-datos-condiciones__bottom-btns">
                            <div className="o-form-autorizacion-datos-condiciones__bottom-btn">
                                <Button
                                    id={"btn-continuar"}
                                    type={"button"}
                                    classname={`secondary`}
                                    text={"Continuar" || "Send"}
                                    isDisabled={false}
                                    onClickAction={() => {
                                        canContinue();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FormAutorizacionDatosYCondiciones;
